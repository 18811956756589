import React, { useEffect, useState } from "react";
import "./CartsProduct.css";
import PriceDetail from "./PriceDetail";
import CartsAllProduct from "./CartsAllProduct";
import DefaultContact from "./DefaultContact";
import NeedHelp from "./NeedHelp";
import Carts_Breadcrum from "./Carts_Breadcrum";
import Promotions from "../HomePage/Promotions/Promotions";
import WalletPayment from "./WalletPayment";
import { API_BASE_PAYMENT_URL } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import {
  addToCartsAsync,
  fetchAllCartsAsync,
  removeFromCartsAsync,
  selectAllCarts,
} from "../../features/cartPage/cartPageSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function CartsProduct() {
  const dispatch = useDispatch();
  const cart = useSelector(selectAllCarts);
  const [currentStep, setCurrentStep] = useState(1);
  const userDetail = useSelector(selectUser);
  const [showContactDetail, setShowContactDetail] = useState(true);
  const [showDefaultContact, setShowDefaultContact] = useState(false);
  const [confirmOverlay, setConfirmOverlay] = useState(false);
  const [paymentSessionId, setPaymentSessionId] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [generatePaymentUrl, setGeneratePaymentUrl] = useState(false);
  const [quantityAdjusted, setQuantityAdjusted] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail && userDetail?.UserID)
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
  }, [dispatch, userDetail]);
  
  const handleConfirmOverlay = () => {
    setConfirmOverlay(true);
  };

  const handleCheckout = () => {
    setShowContactDetail(false);
    setShowDefaultContact(false);
  };

  const handleAddNewAddress = () => {
    setShowContactDetail(true);
    setShowDefaultContact(false);
  };

  const handleGeneratePaymentURL = async (price) => {
    try {
      const response = await fetch(
        `${API_BASE_PAYMENT_URL}${userDetail?.UserID}/${price}`
      );

      const data = await response.json();
      setPaymentSessionId(data?.Payment_Session_ID);
      setTransactionId(data?.LinkID);
      // console.log(data);
    } catch (error) {
      console.error("An error occurred during the API request:", error.message);
    }
  };

  const handleRemoveFromCarts = (SKU_id) => {
    let cartData = {
      userID: userDetail?.UserID,
      SKU: SKU_id,
    };
    dispatch(removeFromCartsAsync(cartData)).then(() => {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
    });
  };

  const handleChangeQtyOfProductCarts = (data, newQty) => {
    let cartData = {
      userID: userDetail?.UserID,
      SKU: data?.skuDetails?.SKU_ID,
      buyQty: newQty,
    };
    if (newQty > 0 && newQty <= data?.skuDetails?.qty) {
      dispatch(addToCartsAsync(cartData)).then(() => {
        dispatch(fetchAllCartsAsync(userDetail?.UserID));
      });
    }
  };

  useEffect(() => {
    // Function to handle adjusting quantity
    const fetchData = async () => {
      handleOpen();
      const adjustQuantityToAvailable = async () => {
        cart?.forEach((item) => {
          const availableQuantity = item?.skuDetails?.qty || 0;

          // Remove product if available quantity is 0
          if (availableQuantity < parseInt(item?.skuDetails?.Bundle)) {
            handleRemoveFromCarts(item?.skuDetails?.SKU_ID);
            setQuantityAdjusted(true);
            return; // Skip processing further for this item
          }

          const updatedQty = Math.min(item?.buy_quantity, availableQuantity);
          const newQtys =
            (parseInt(availableQuantity) / parseInt(item?.skuDetails?.Bundle)) *
            parseInt(item?.skuDetails?.Bundle);

          // Update quantity only if buy_quantity exceeds available quantity
          if (item.buy_quantity !== updatedQty) {
            // const decrementCount = Math.max(0, item?.buy_quantity - updatedQty);
            handleChangeQtyOfProductCarts(item, newQtys);
            setQuantityAdjusted(true);
          }
        });

        if (quantityAdjusted) {
          await dispatch(fetchAllCartsAsync(userDetail?.UserID));
        }
        handleClose();
      };
      // Call the function to adjust quantities when component mounts
      adjustQuantityToAvailable();
    };
    fetchData();
    return () => clearTimeout(2000);
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="cart-breadcrum-main-container">
        <Carts_Breadcrum
          confirmOverlay={confirmOverlay}
          setConfirmOverlay={setConfirmOverlay}
          handleConfirmOverlay={handleConfirmOverlay}
          onCheckout={handleCheckout}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleGeneratePaymentURL={handleGeneratePaymentURL}
          paymentSessionId={paymentSessionId}
          transactionId={transactionId}
          setGeneratePaymentUrl={setGeneratePaymentUrl}
        />
      </div>
      <div className="carts_product-align-container">
        {currentStep === 1 && (
          <div className="carts-container">
            {currentStep === 1 && <CartsAllProduct />}
          </div>
        )}
        {currentStep === 2 && (
          <div className="carts-container-step2">
            {currentStep === 2 && (
              <DefaultContact onAddNewAddress={handleAddNewAddress} />
            )}
          </div>
        )}
        {currentStep === 3 && (
          <div className="carts-container-step3">
            {currentStep === 3 && (
              <WalletPayment
                confirmOverlay={confirmOverlay}
                setConfirmOverlay={setConfirmOverlay}
                handleConfirmOverlay={handleConfirmOverlay}
                onCheckout={handleCheckout}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                handleGeneratePaymentURL={handleGeneratePaymentURL}
                paymentSessionId={paymentSessionId}
                transactionId={transactionId}
                generatePaymentUrl={generatePaymentUrl}
                setGeneratePaymentUrl={setGeneratePaymentUrl}
              />
            )}
          </div>
        )}
        {currentStep !== 3 && (
          <div className="cartsProduct-estimates-container">
            <div className="cartsProduct-checkout-container">
              <PriceDetail
                confirmOverlay={confirmOverlay}
                setConfirmOverlay={setConfirmOverlay}
                handleConfirmOverlay={handleConfirmOverlay}
                onCheckout={handleCheckout}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                handleGeneratePaymentURL={handleGeneratePaymentURL}
                paymentSessionId={paymentSessionId}
                transactionId={transactionId}
              />
            </div>
          </div>
        )}
      </div>
      {currentStep === 1 && <Promotions />}
      <NeedHelp />
    </>
  );
}

export default CartsProduct;
