import React, { useEffect, useState } from "react";
import "./MobileCarts.css";
import MobileCartsAllProduct from "./MobileCartsAllProduct";
import MobileDefaultContact from "./MobileDefaultContact";
import MobilePriceDetail from "./MobilePriceDetail";
import MobileCartBreadcrum from "./MobileCartBreadcrum";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCartsAsync,
  fetchAllCartsAsync,
  removeFromCartsAsync,
  selectAllCarts,
} from "../../features/cartPage/cartPageSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { selectUser } from "../../features/auth/authSlice";

function MobileCarts() {
  const dispatch = useDispatch();
  const cart = useSelector(selectAllCarts);
  const userDetail = useSelector(selectUser);
  const [currentStep, setCurrentStep] = useState(1);
  const [showContactDetail, setShowContactDetail] = useState(true);
  const [showDefaultContact, setShowDefaultContact] = useState(false);
  const [quantityAdjusted, setQuantityAdjusted] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    handleOpen();
    if (userDetail && userDetail?.UserID)
      dispatch(fetchAllCartsAsync(userDetail?.UserID)).then(()=>{
    handleClose();
  });
  }, [dispatch, userDetail]);

  const handleCheckout = () => {
    setShowContactDetail(false);
    setShowDefaultContact(false);
  };

  const handleAddNewAddress = () => {
    setShowContactDetail(true);
    setShowDefaultContact(false);
  };

  const handleRemoveFromCarts = (SKU_id) => {
    handleOpen();
    let cartData = {
      userID: userDetail?.UserID,
      SKU: SKU_id,
    };
    dispatch(removeFromCartsAsync(cartData)).then(() => {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
      handleClose();
    });
  };

  const handleChangeQtyOfProductCarts = (data, newQty) => {
    handleOpen();
    let cartData = {
      userID: userDetail?.UserID,
      SKU: data?.skuDetails?.SKU_ID,
      buyQty: newQty,
    };
    if (newQty > 0 && newQty <= data?.skuDetails?.qty) {
      dispatch(addToCartsAsync(cartData)).then(() => {
        dispatch(fetchAllCartsAsync(userDetail?.UserID));
        handleClose();
      });
    }
  };

  useEffect(() => {
    // Function to handle adjusting quantity
    const fetchData = async () => {
      handleOpen();
      const adjustQuantityToAvailable = async () => {
        cart?.forEach((item) => {
          const availableQuantity = item?.skuDetails?.qty || 0;

          // Remove product if available quantity is 0
          if (availableQuantity < parseInt(item?.skuDetails?.Bundle)) {
            handleRemoveFromCarts(item?.skuDetails?.SKU_ID);
            setQuantityAdjusted(true);
            return; // Skip processing further for this item
          }

          const updatedQty = Math.min(item?.buy_quantity, availableQuantity);
          const newQtys =
            (parseInt(availableQuantity) / parseInt(item?.skuDetails?.Bundle)) *
            parseInt(item?.skuDetails?.Bundle);

          // Update quantity only if buy_quantity exceeds available quantity
          if (item.buy_quantity !== updatedQty) {
            // const decrementCount = Math.max(0, item?.buy_quantity - updatedQty);
            handleChangeQtyOfProductCarts(item, newQtys);
            setQuantityAdjusted(true);
          }
        });

        if (quantityAdjusted) {
          await dispatch(fetchAllCartsAsync(userDetail?.UserID));
        }
        handleClose();
      };
      // Call the function to adjust quantities when component mounts
      adjustQuantityToAvailable();
    };
    fetchData();
    return () => clearTimeout(2000);
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mobile-cartsProduct-breadcrum-container">
        <MobileCartBreadcrum
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      </div>
      <div className="mobile-cartsProduct-container">
        <div
          className="mobile-carts-container"
          style={{ minHeight: currentStep === 3 && "0" }}
        >
          {currentStep === 1 && <MobileCartsAllProduct />}
          {currentStep === 2 && (
            <MobileDefaultContact onAddNewAddress={handleAddNewAddress} />
          )}
        </div>
      </div>
      <div className="mobile-cartsProduct-estimates-container">
        <div className="mobile-cartsProduct-checkout-container">
          <MobilePriceDetail
            onCheckout={handleCheckout}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </div>
      </div>
    </>
  );
}

export default MobileCarts;
