

import freeDelivery from "./img/car-logo.png";
import freeReturns from "./img/loan-logo.png";
import quickTracking from "./img/quick-track.png";
import expressDelivery from "./img/aeroplane.png";
import userF1 from "./img/User-Female/user-f1.png";
import userF2 from "./img/User-Female/user-f2.png";
import userF3 from "./img/User-Female/user-f3.png";
import userF4 from "./img/User-Female/user-f4.png";
import userF5 from "./img/User-Female/user-f5.png";
import userF6 from "./img/User-Female/user-f6.png";
import userF7 from "./img/User-Female/user-f7.png";
import userF8 from "./img/User-Female/user-f8.png";
import userF9 from "./img/User-Female/user-f9.png";
import userF10 from "./img/User-Female/user-f10.png";
import userF11 from "./img/User-Female/user-f11.png";
import userF12 from "./img/User-Female/user-f12.png";
import userF13 from "./img/User-Female/user-f13.png";
import userF14 from "./img/User-Female/user-f14.png";
import userF15 from "./img/User-Female/user-f15.png";
import userF16 from "./img/User-Female/user-f16.png";
import userF17 from "./img/User-Female/user-f17.png";

import userM1 from "./img/User-Male/user-m1.png";
import userM2 from "./img/User-Male/user-m2.png";
import userM3 from "./img/User-Male/user-m3.png";
import userM4 from "./img/User-Male/user-m4.png";
import userM5 from "./img/User-Male/user-m5.png";
import userM6 from "./img/User-Male/user-m6.png";
import userM7 from "./img/User-Male/user-m7.png";
import userM8 from "./img/User-Male/user-m8.png";
import userM9 from "./img/User-Male/user-m9.png";
import userM10 from "./img/User-Male/user-m10.png";
import userM11 from "./img/User-Male/user-m11.png";
import userM12 from "./img/User-Male/user-m12.png";
import userM13 from "./img/User-Male/user-m13.png";
import userM14 from "./img/User-Male/user-m14.png";
import userM15 from "./img/User-Male/user-m15.png";
import userM16 from "./img/User-Male/user-m16.png";
import userM17 from "./img/User-Male/user-m17.png";

export const findqty = (sku_id, qtys) => {
  if (!qtys) {
    return 0; // Return 0 if qtys is null or undefined
  }

  const quantityArray = sku_id?.startsWith("D") ? qtys?.DealerQuantity : qtys?.CCQuantity;

  if (quantityArray) {
    for (const arr of quantityArray) {
      for (const product of arr) {
        if (product?.SKU_ID === sku_id) {
          return product?.qty; // Return the quantity if SKU_ID matches
        }
      }
    }
  }

  return 0; // Return 0 if no matching SKU_ID is found
};


export const findPDPqty = (sku_id, qtys) => {
  if (qtys) {
    for (const arr of qtys) {
      if (arr.SKU_ID === sku_id) {
        return arr.qty;
      }
    }
  }

  return 0;
};

export const randomMaleLogo = [
  userM1,
  userM2,
  userM3,
  userM4,
  userM5,
  userM6,
  userM7,
  userM8,
  userM9,
  userM10,
  userM11,
  userM12,
  userM13,
  userM14,
  userM15,
  userM16,
  userM17,
];

export const randomFemaleLogo = [
  userF1,
  userF2,
  userF3,
  userF4,
  userF5,
  userF6,
  userF7,
  userF8,
  userF9,
  userF10,
  userF11,
  userF12,
  userF13,
  userF14,
  userF15,
  userF16,
  userF17,
];

export const banners = [
  {
    id: 1,
    img: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Hero-Banner-1.jpg",
  },
  {
    id: 2,
    img: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Hero-Banner-2.jpg",
  },
  {
    id: 3,
    img: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Hero-Banner-3.jpg",
  },
  {
    id: 4,
    img: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Hero-Banner-4.jpg",
  },
  {
    id: 5,
    img: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Hero-Banner-5.jpg",
  },
  {
    id: 6,
    img: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Hero-Banner-6.jpg",
  },
  {
    id: 7,
    img: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Hero-Banner-7.jpg",
  },
  {
    id: 8,
    img: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Hero-Banner-8.jpg",
  },
];

export const services = [
  {
    icon: freeDelivery,
    heading: "Free Delivery",
    desc: "Free delivery for purchasing above ₹3000",
  },
  {
    icon: freeReturns,
    heading: "Hassle Free Returns",
    desc: "If you are not satisfied you can return the product within 14 days",
  },
  {
    icon: quickTracking,
    heading: "Quick Tracking Info",
    desc: "We'll send you tracking info once your order is dispatched",
  },
  {
    icon: expressDelivery,
    heading: "Express Delivery",
    desc: "In express delivery you will be charged even purchasing above ₹3000",
  },
];

export const cratoniData = {
  brand: "CRATONI Helmets GmbH",
  description:
    "CRATONI Helmets GmbH is one of the most innovative and biggest safety-helmet manufacturers in the World. The Head Protection specialist Cratoni is a globally active premium brand that has been shaping the market since 1985 with innovative technology and trendy designs. In addition to helmets, CRATONI produces sports glasses. Professional teams like the Ghost Factory Racing Team recognize the outstanding quality of CRATONI and use the products in their competitions. CRATONI has been ensuring that ambitious outdoor, professional, and amateur sportspeople can make the right choice with an innovative range of helmets. Many champions, from Olympic champions to world champions, have relied on the performance of Cratoni helmets.",
  history: {
    foundingYear: 1990,
    focus:
      "Since 1990, CRATONI concentrates on the manufacturing and distribution of bicycle helmets and was the first company in the world to develop the revolutionary HEAD-RING-SYSTEM back in the year 1991, for one helmet size to fit all heads! It is a little known fact that CRATONI originated the helmet retention craze which set a new standard in helmet performance.",
  },
  productDevelopment: {
    collaboration:
      "The know-how of many internationally successful pro-athletes flows into our product development. In close cooperation with professional athletes, CRATONI creates top-class safety helmets regarding design, fit, and safety. This close cooperation with active sportsmen makes revolutionary innovations possible.",
  },
  distribution: {
    agreements:
      "Determined to supply the market with quality products, CRATONI reached exclusive distribution agreements with partners in more than 70 countries worldwide.",
  },
};

export const funnData = {
  brand: "Funn MTB Components",
  slogan: "Elevate Your Ride",
  description:
    "Unleash the Thrill, Master the Trail. Funn Components have been in business since 1997 and have spent the last 20 years helping riders to have more fun on their bikes. Step into a world where precision engineering meets the raw excitement of mountain biking. Born from a passion for pushing the limits on the trail, Funn is dedicated to providing riders with top-tier, high-performance components that redefine what's possible on two wheels.",
  legacy: {
    history:
      "Funn MTB Components has a rich history rooted in the heart of mountain biking. In the early days, Funn components was founded on a passion for World Cup results, best-of-the-best elite racers and shaving nano-seconds off the clock. Athletes like Sam Hill, Brendan Fairclough, Greg Minnaar and hundreds of other greatest-of-all-time heroes of MTB have ridden Funn components.",
  },
  innovation: {
    commitment:
      "Our commitment to innovation is reflected in every component we create. Funn's R&D team tirelessly explores new materials, manufacturing techniques, and design concepts to ensure our products deliver the ultimate in performance and durability. When you ride with Funn, you're riding with the latest advancements in MTB technology.",
  },
  performance: {
    testing:
      "Funn components are not just designed; they are meticulously tested on the trails. We understand the demands of aggressive riding, and that's why our products undergo rigorous testing in real-world conditions. From handlebars to pedals, each component is crafted to withstand the toughest challenges, providing you with confidence and control on every ride.",
  },
  riders: {
    "2023Team": [
      "Matthew Fairbrothers",
      "Brittany Falconer",
      "Eliot Lees",
      "McKenna Meerten",
      "Capella Martin",
      "Elof Lind",
      "Olivier Cuvet",
      "Jakub Vencl",
    ],
    specialties: {
      EnduroRacing: ["Matthew Fairbrothers", "Brittany Falconer"],
      Downhill: ["Eliot Lees", "McKenna Meerten", "Capella Martin"],
      Slopestyle: ["Elof Lind", "Olivier Cuvet", "Jakub Vencl"],
    },
  },
  style: {
    description:
      "Funn MTB Components aren't just about performance; they're also about expressing your unique style on the trail. Our products feature sleek designs and eye-catching graphics, allowing you to stand out while conquering the toughest trails.",
  },
};

export const graniteData = {
  brand: "Granite Design",
  slogan: "Born on the Trails",
  description:
    "Granite Design is a pioneering brand known for its innovative and high-quality cycling accessories and components. With a commitment to excellence and a passion for cycling, Granite Design has become a trusted name among cyclists worldwide. Granite Design is the recipient of various awards for its innovative solutions for cyclists.",
  productLineup: {
    description:
      "Granite Design offers a diverse range of cycling accessories designed to enhance the riding experience and make cycling more enjoyable and convenient for riders of all levels.",
    categories: [
      {
        name: "Bikepacking Essentials",
        description:
          "Essential accessories for bikepacking adventures, ensuring convenience and functionality on the trail.",
      },
      {
        name: "Maintenance Tools",
        description:
          "High-quality tools designed to facilitate bike maintenance and repairs, ensuring optimal performance and longevity of your bike.",
      },
      {
        name: "Other Accessories",
        description:
          "A variety of accessories catering to different needs and preferences, including style, convenience, and functionality.",
      },
    ],
  },
  attributes: {
    innovativeDesign: true,
    durability: true,
    functionality: true,
    versatility: true,
    commitmentToSustainability: true,
  },
  partnership: {
    partner: "CycleCircle",
    description:
      "CycleCircle proudly partners with Granite Design to offer their innovative and high-quality products to our customers.",
  },
};

export const moonData = {
  brand: "Moon-Sports",
  slogan:
    "Where Innovation Meets Adventure in the World of Cycling Illumination",
  description:
    "Moon Sports Lights have been producing lights since 1996, and continue till this day to explore and innovate the industry of bicycle lights, ensuring cyclist can ride at all hours of the day and night. Moon Lights are designed, engineered, developed and produced by passionate cyclists for cyclists. At Moon-Sports, we're not just about creating bicycle lights; we're about crafting an unparalleled cycling experience. Discover a world where cutting-edge technology meets sleek design, ensuring you ride with confidence, day or night.",
  innovation: {
    description:
      "Stay ahead of the curve by integrating the latest LED and battery technologies into Moon-Sports lights. Experience unmatched brightness, extended battery life, and consistent performance on every ride.",
  },
  design: {
    description:
      "Elevate your bike's aesthetics with Moon lights. Our design team ensures that every product not only enhances your visibility but also complements your bike's style seamlessly.",
  },
  safety: {
    description:
      "Rider’s safety is Moon lights top priority. Our lights undergo rigorous testing to meet and exceed industry standards, providing you with the confidence to ride any time, anywhere.",
  },
  versatility: {
    description:
      "Whether you're a daily commuter, a weekend warrior, or a thrill-seeking off-road enthusiast, Moon-Sports has the perfect lighting solution for you. Explore our range and find the ideal companion for your cycling adventures.",
  },
  sustainability: {
    description:
      "At Moon-Sports, we're committed to preserving the environment. Our manufacturing processes prioritize sustainability, using eco-friendly materials and energy-efficient technologies to minimize our impact on the planet.",
  },
};

export const Product = {
  id: 1,
  name: "Veloheld iconX",
  slug: "new-luxury-laptop",
  photo:
    "https://images.pexels.com/photos/1029757/pexels-photo-1029757.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  price: 1999.0,
  desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque consectetur vero asperiores quis animi explicabo accusamus nemo cupiditate harum pariatur! Provident sit tenetur totam mollitia consectetur nesciunt, recusandae obcaecati itaque!",
  images: {
    blueColor: [
      {
        src: "https://images.bike24.com/media/720/i/mb/19/99/7d/iconx-frame-steelfork-glokzin-teal-glossy-1-985567.jpg",
        Varientname: "Diamond",
      },
      {
        src: "https://images.bike24.com/media/720/i/mb/aa/6c/f8/geo-iconx-985566.jpg",
        Varientname: "Prone",
      },
      {
        src: "https://images.bike24.com/media/720/i/mb/d7/23/8a/iconx-frame-steelfork-glokzin-teal-glossy-3-985569.jpg",
        Varientname: "Step-through",
      },
      {
        src: "https://images.bike24.com/media/720/i/mb/30/3f/a1/iconx-frame-steelfork-glokzin-teal-glossy-4-985570.jpg",
        Varientname: "Cantilever",
      },
    ],
    greyColor: [
      {
        src: "https://images.bike24.com/media/720/i/mb/92/2b/90/veloheld-iconx-titan-frame-1-1234886.jpg",
        Varientname: "Diamond",
      },
      {
        src: "https://images.bike24.com/media/720/i/mb/b4/fa/63/geo-veloheld-iconx-titan-frame-1234844.jpg",
        Varientname: "Prone",
      },
      {
        src: "https://images.bike24.com/media/720/i/mb/17/11/9c/veloheld-iconx-titan-frame-2-1234887.jpg",
        Varientname: "Step-through",
      },
      {
        src: "https://images.bike24.com/media/720/i/mb/02/0e/f0/veloheld-iconx-titan-frame-5-1234890.jpg",
        Varientname: "Cantilever",
      },
    ],
    yellowColor: [
      {
        src: "https://images.bike24.com/media/720/i/mb/5a/46/39/geo-iron-frame-yellow-1-1234987.jpg",
        Varientname: "Diamond",
      },
      {
        src: "https://images.bike24.com/media/720/i/mb/9c/0c/a4/geo-iron-frame-1234986.jpg",
        Varientname: "Prone",
      },
      {
        src: "https://images.bike24.com/media/720/i/mb/79/79/c7/geo-iron-frame-yellow-2-1234988.jpg",
        Varientname: "Step-through",
      },
      {
        src: "https://images.bike24.com/media/720/i/mb/1a/8b/ea/geo-iron-frame-yellow-6-1234992.jpg",
        Varientname: "Cantilever",
      },
    ],
    purpleColor: [
      {
        src: "https://images.bike24.com/media/720/i/mb/19/99/7d/iconx-frame-steelfork-glokzin-teal-glossy-1-985567.jpg",
        Varientname: "Diamond",
      },
      {
        src: "https://images.bike24.com/media/720/i/mb/aa/6c/f8/geo-iconx-985566.jpg",
        Varientname: "Prone",
      },
      {
        src: "https://images.bike24.com/media/720/i/mb/d7/23/8a/iconx-frame-steelfork-glokzin-teal-glossy-3-985569.jpg",
        Varientname: "Step-through",
      },
      {
        src: "https://images.bike24.com/media/720/i/mb/30/3f/a1/iconx-frame-steelfork-glokzin-teal-glossy-4-985570.jpg",
        Varientname: "Cantilever",
      },
    ],
  },
  colors: ["blue", "gray", "yellow", "purple"],
  infos: [
    {
      title: "Compatibility",
    },
    {
      title: "Technical Data",
    },
    {
      title: "How To",
    },
    {
      title: "Reviews",
    },
  ],
  discount: 20,
  sold: 52,
  category: "Frameset",
  brand: "Veloheld",
};

export const product_description = {
  aboutBrands: {
    productName: "S-Works",
    productDesc:
      "S-Works is not a separate company but rather a premium product line offered by Specialized Bicycle Components, which is a prominent American bicycle manufacturing company. Specialized was founded in 1974 by Mike Sinyard and has since become one of the most recognized and respected brands in the cycling industry.",
    productFeature:
      "Here are some key details about Specialized Bicycle Components:",
    productLi: [
      {
        para: "History: Specialized has a rich history in the cycling industry. It started as a small bike shop in California and gradually grew into a global brand known for innovation and quality.",
      },
      {
        para: "Product Range: Specialized produces a wide range of bicycles, including road bikes, mountain bikes, electric bikes (e-bikes), commuter bikes, and more. They also manufacture cycling accessories, components, apparel, and equipment.",
      },
      {
        para: " Innovation: Specialized is known for its commitment to research and development. They have introduced numerous technological advancements in cycling, such as the first mass-produced mountain bike, the Stumpjumper, in 1981. They continue to innovate with features like advanced frame materials, suspension systems, and aerodynamic designs.",
      },
    ],
  },
  descriptions: {
    productHeading: "S-Works Tarmac SL7 Frameset",
    productDescs:
      "The S-Works Tarmac SL7 Frameset is part of Specialized's Tarmac lineup, which has a long history of success in professional cycling. This frameset is the foundation upon which many professional road racing bikes are built and is used by some of the world's top cyclists. It's a meticulously engineered and finely crafted piece of cycling technology designed to deliver maximum speed and efficiency.",
    productFeatures: "Key Features:",
    productLis: [
      {
        para: "Lightweight Construction: The Tarmac SL7 frameset is constructed using Specialized's highest-grade FACT Carbon, a proprietary carbon fiber material. This ensures an exceptional strength-to-weight ratio, resulting in a lightweight frame that's ideal for climbing and acceleration.",
      },
      {
        para: "Aerodynamic Design: The Tarmac SL7 features a highly aerodynamic shape. Specialized has utilized its expertise in wind tunnel testing to optimize the tube shapes and profiles, reducing aerodynamic drag and helping riders cut through the air more efficiently.",
      },
      {
        para: "Stiffness and Responsiveness: This frameset boasts outstanding stiffness characteristics, especially in the bottom bracket area and head tube, which results in exceptional power transfer and precise handling. It responds quickly to rider input, making it suitable for aggressive riding and sprinting.",
      },
    ],
  },
};
