import React, { useState } from "react";
import "./MobileCartsAllProduct.css";
import wishlistMoreLogo from "../../img/wishlist-more.png";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp";
import { useNavigate } from "react-router";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import {
  fetchAllCartsAsync,
  selectAllCarts,
  removeAllFromCartsAsync,
  removeFromCartsAsync,
  addToCartsAsync,
} from "../../features/cartPage/cartPageSlice";
import KeyboardArrowUpSharpIcon from "@mui/icons-material/KeyboardArrowUpSharp";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import { IMAGE_BASE } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import MobileCartNoProduct from "./MobileCartNoProduct";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { calculateDealerPrice, calculateOfferPrice, formatWithCommasWithDecimal } from "../../utils/utils";

function MobileCartsAllProduct() {
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const cart = useSelector(selectAllCarts);
  // console.log(cart);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const navigate = useNavigate();
  const [openAccordian, setOpenAccordian] = useState(null);

  const handleAddItemFromWishlist = () => {
    navigate("/wishlist");
  };
  const handleContinueShopping = () => {
    navigate("/");
  };


  const handleRemoveAllFromCarts = () => {
    handleOpen()
    dispatch(removeAllFromCartsAsync(userDetail.UserID)).then(() => {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
      handleClose();
    });
  };

  
 
  const handleRemoveFromCarts = (data) => {
    handleOpen();
    let cartData = {
      userID: userDetail?.UserID,
      SKU: data?.skuDetails?.SKU_ID,
    };
    dispatch(removeFromCartsAsync(cartData)).then(() => {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
      handleClose();
    });
  };
  const handleChangeQtyOfProductCarts = (data, newQty, Action) => {
    handleOpen();
    let cartData = {
      userID: userDetail?.UserID,
      SKU: data?.skuDetails?.SKU_ID,
      buyQty: newQty,
    };
    if (newQty > 0 && newQty <= data?.skuDetails?.qty) {
      dispatch(addToCartsAsync(cartData)).then(() => {
        dispatch(fetchAllCartsAsync(userDetail?.UserID));
        handleClose();
      });
    }
  };

  

  const handleAccordian = (index) => {
    if (openAccordian !== index) {
      setOpenAccordian(index);
    } else {
      setOpenAccordian(null);
    }
  };
  

  const handleCurrentPrice = (data) => {
    const originalPrice = data?.skuDetails?.Offer_price
      ? calculateOfferPrice(
          parseInt(data?.skuDetails?.MRP_price),
          parseInt(data?.skuDetails?.Offer_price),
          data?.skuDetails?.GST
        )
      : calculateDealerPrice(
          parseInt(data?.skuDetails?.MRP_price),
          parseInt(data?.skuDetails?.NDP_Price),
          data?.skuDetails?.GST
        );
    const discountedPrice = originalPrice;
    const finalPrice = discountedPrice * data?.buy_quantity;
    return finalPrice;
  };

  const calculateGST = (price, gstPercentage) => {
    // Ensure both price and gstPercentage are valid numbers
    if (
      typeof price !== "number" ||
      isNaN(price) ||
      typeof gstPercentage !== "number" ||
      isNaN(gstPercentage)
    ) {
      throw new Error(
        "Invalid input. Price and GST percentage must be numbers."
      );
    }

    // Calculate GST amount
    const gstAmount = (price * gstPercentage) / 100;
    return gstAmount;
  };
  return (
    <>
      <div className="mobile-carts-all-product-container">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="mobile-continue-remove-items-div">
          <span
            className="mobile-continue-to-shopping-btn"
            onClick={handleContinueShopping}
          >
            <ChevronLeftSharpIcon /> Continue to shopping
          </span>
          {cart && cart?.length > 0 && (
            <span
              className="mobile-remove-all-items-btn"
              onClick={handleRemoveAllFromCarts}
            >
              Remove all items
            </span>
          )}
        </div>
        <div className="mobile-main-carts-products-div-container">
          {cart && cart.length > 0 ? (
            <div className="mobile-main-carts-products-contain-div">
              {cart?.map((data, index) => (
                <div className="mobile-main-carts-product-div-each-container">
                  <div className="mobile-carts-product-delete">
                    <CloseIcon onClick={() => handleRemoveFromCarts(data)} />
                  </div>
                  <div className="mobile-main-carts-product-div-each-main-container">
                    <div className="mobile-main-carts-product-img-div"  onClick={() =>
                              navigate(
                                `/product/${
                                  data?.skuDetails?.prod_ID &&
                                  data?.skuDetails?.prod_ID
                                }`
                              )
                            }>
                      <img
                        src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                          data?.images && data?.images
                        }`}
                        alt=""
                      />
                    </div>
                    {data?.skuDetails?.SKU_ID?.startsWith("D") && (
                      <span
                        className="main-carts-product-not-available"
                        style={{
                          color: "var(--color-peach)",
                          fontSize: "12px",
                          fontWeight: "700",
                          position: "absolute",
                          top: "5px",
                          right: "10px",
                        }}
                      >
                        Not Available
                      </span>
                    )}
                    <div className="mobile-main-carts-product-details-div">
                      <div className="mobile-main-carts-products-brands-logo">
                        {data && data?.skuDetails?.manufacture_images ? (
                          <img
                            className="mobile-main-carts-product-brand-img"
                            src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                              data && data?.skuDetails?.manufacture_images
                            }`}
                            alt=""
                          />
                        ) : (
                          <h3 className="mobile-main-parts-product-name">
                            {data?.skuDetails?.manufacture_name &&
                              data?.skuDetails?.manufacture_name}
                          </h3>
                        )}
                      </div>
                      <span className="mobile-main-carts-product-name">
                        {data?.skuDetails?.prod_name &&
                          (data?.skuDetails?.prod_name)
                            .slice(0, 45)
                            .toLowerCase()
                            .charAt(0)
                            .toUpperCase() +
                            (data?.skuDetails?.prod_name)
                              .slice(0, 45)
                              .toLowerCase()
                              .slice(1)}
                        {(data?.skuDetails?.prod_name).length > 45 && "..."}
                      </span>
                      <div className="mobile-main-carts-product-ratings-div">
                        <div className="mobile-main-carts-products-avg-rating-div">
                          <span>4.7</span>
                          <StarRoundedIcon />
                        </div>
                        <span className="rating-divider">|</span>
                        <span className="mobile-main-carts-products-ratings-total">
                          4K
                        </span>
                      </div>
                      <div className="mobile-main-carts-products-price-div">
                        <span className="mobile-main-carts-products-sale-price">
                          Dealer Price- ₹
                          <b
                            style={{
                              textDecoration:
                                data?.skuDetails?.Offer_price !== null &&
                                "line-through",
                              fontSize:
                                data?.skuDetails?.Offer_price !== null && "3vw",
                            }}
                          >
                            {formatWithCommasWithDecimal(
                              calculateDealerPrice(
                                parseInt(data?.skuDetails?.MRP_price),
                                parseInt(data?.skuDetails?.NDP_Price),
                                data?.skuDetails?.GST
                              )
                            )}
                          </b>
                          {Object.keys(userDetail).length > 0 &&
                            data?.skuDetails?.Offer_price !== null && (
                              <span className="mobile-promotion-product-offer-price">
                                ₹
                                {formatWithCommasWithDecimal(
                                  calculateOfferPrice(
                                    parseInt(data?.skuDetails?.MRP_price),
                                    parseInt(data?.skuDetails?.Offer_price),
                                    data?.skuDetails?.GST
                                  )
                                )}
                              </span>
                            )}
                        </span>
                        <span className="mobile-main-carts-products-mrp-price">
                          Product MRP- ₹
                          {formatWithCommasWithDecimal(
                            parseInt(data?.skuDetails?.MRP_price)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mobile-main-carts-products-qty-price-details">
                    <div className="mobile-main-carts-products-qty">
                      <span>Qty</span>
                      <div className="mobile-main-carts-products-inc-desc-qty">
                        <RemoveSharpIcon
                          onClick={() => {
                            const bundleSize = parseInt(
                              data?.skuDetails?.Bundle
                            );
                            const currentQuantity = parseInt(
                              data?.buy_quantity
                            );

                            if (currentQuantity === bundleSize) {
                              return;
                            } else {
                              const newQuantity = Math.max(
                                currentQuantity - bundleSize,
                                0
                              ); // Ensure the quantity doesn't go below 0
                              handleChangeQtyOfProductCarts(
                                data,
                                newQuantity,
                                "decrement"
                              );
                            }
                          }}
                          className={
                            data?.buy_quantity === 1 ? "mobile-disabled" : ""
                          }
                        />

                        <span className="mobile-main-carts-qty-added">
                          {data?.buy_quantity}
                        </span>
                        <AddSharpIcon
                          onClick={() => {
                            const bundleSize = parseInt(
                              data?.skuDetails?.Bundle
                            );
                            const currentQuantity = parseInt(
                              data?.buy_quantity
                            );

                            const newQuantity = currentQuantity + bundleSize;

                            handleChangeQtyOfProductCarts(
                              data,
                              newQuantity,
                              "increment"
                            );
                          }}
                          className={
                            data?.buy_quantity === data?.skuDetails?.qty
                              ? "mobile-disabled"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="mobile-main-carts-products-price-accordian-open">
                      <div className="mobile-main-carts-products-show-price">
                        <span className="mobile-main-carts-products-pricing">
                          ₹{" "}
                          {formatWithCommasWithDecimal(
                            parseInt(
                              data?.skuDetails?.Offer_price
                                ? calculateOfferPrice(
                                    parseInt(data?.skuDetails?.MRP_price),
                                    parseInt(data?.skuDetails?.Offer_price),
                                    data?.skuDetails?.GST
                                  )
                                : calculateDealerPrice(
                                    parseInt(data?.skuDetails?.MRP_price),
                                    parseInt(data?.skuDetails?.NDP_Price),
                                    data?.skuDetails?.GST
                                  )
                            ) * data?.buy_quantity
                          )}
                        </span>
                        <span className="mobile-main-carts-products-price-details-click">
                          Price Details
                        </span>
                      </div>
                      {openAccordian !== index && (
                        <KeyboardArrowDownSharpIcon
                          className="accordian-carts-icon"
                          style={{ fontSize: "1.8rem" }}
                          onClick={() => handleAccordian(index)}
                        />
                      )}
                      {openAccordian === index && (
                        <KeyboardArrowUpSharpIcon
                          className="accordian-carts-icon"
                          style={{ fontSize: "1.8rem" }}
                          onClick={() => handleAccordian(index)}
                        />
                      )}
                    </div>
                  </div>
                  {openAccordian === index && (
                    <div className="mobile-main-carts-products-pricing-details-accordian-container">
                      <div className="mobile-product-pricing-details">
                        <div className="mobile-product-pricing-div">
                          <p>Price Per Quantity</p>
                          <hr />
                          <span>
                            ₹
                            {formatWithCommasWithDecimal(
                              parseInt(
                                data?.skuDetails?.Offer_price
                                  ? calculateOfferPrice(
                                      parseInt(data?.skuDetails?.MRP_price),
                                      parseInt(data?.skuDetails?.Offer_price),
                                      data?.skuDetails?.GST
                                    )
                                  : calculateDealerPrice(
                                      parseInt(data?.skuDetails?.MRP_price),
                                      parseInt(data?.skuDetails?.NDP_Price),
                                      data?.skuDetails?.GST
                                    )
                              )
                            )}
                          </span>
                        </div>
                        <div className="mobile-product-pricing-div">
                          <p>GST</p>
                          <hr />
                          <span>
                            ₹
                            {formatWithCommasWithDecimal(
                              calculateGST(
                                parseInt(
                                  data?.skuDetails?.NDP_Price &&
                                    data?.skuDetails?.NDP_Price
                                ),
                                parseInt(
                                  data?.skuDetails?.GST && data?.skuDetails?.GST
                                )
                              )
                            )}
                          </span>
                        </div>
                        <div className="mobile-product-pricing-div">
                          <p>Shipping To</p>
                          <hr />
                          <span>
                            {userDetail?.location}-{userDetail?.pin_code}
                          </span>
                        </div>

                        <div className="mobile-product-pricing-div">
                          <p className="mobile-total-price-text">Total Price</p>
                          <hr />
                          <span className="mobile-total-price-text">
                            ₹{formatWithCommasWithDecimal(handleCurrentPrice(data))}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <div className="mobile-add-more-from-wishlist-btn-div">
                <div className="mobile-add-more-from-wishlist-btn">
                  <img src={wishlistMoreLogo} alt="" />
                  <span onClick={handleAddItemFromWishlist}>
                    Add more items from wishlist
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <MobileCartNoProduct text={"carts"} />
          )}
        </div>
      </div>
    </>
  );
}

export default MobileCartsAllProduct;
